import React, { useEffect } from "react";
import SlidingPane from "react-sliding-pane";
import NewWindow from "react-new-window";
import { connect } from "react-redux";
import { getProjectBoltedConnections } from "modules/project/qa/bolted_connections/actions";
import BoltedConnectionsTable from "modules/project/qa/bolted_connections/web/index/BoltedConnectionsTable";
import TableTorch from "modules/5_view_helpers/web/TableTorch";
import { selectModelGUIDS } from "../helpers";

function mapStateToProps(state) {
  return {
    project_qa_bolted_connections: state.project_qa_bolted_connections,
  };
}

let BoltListPane = (props) => {
  const [activeTab, setActiveTab] = React.useState("paint_inspections");
  const { isOpen, toggle, project, showInNewWindow, project_qa_bolted_connections, tc_model_id } = props;

  useEffect(() => {
    props.getProjectBoltedConnections(project?.id);
  }, []);

  const columns = React.useMemo(() => {
    return [
      {
        header: "Main assembly",
        dataKey: "main_assembly",
      },
      {
        header: "Size",
        dataKey: "size",
        headerStyle: { width: "100px" },
        cellRenderer: (cell, row) => `${row?.size} - ${row.grade} - ${row.joint_type}`,
      },
      {
        header: "Batch Number",
        dataKey: "batch_number",
      },
      {
        header: "Tensioned By",
        dataKey: "tensioned_by",
      },
      {
        header: "Inspected By",
        dataKey: "inspected_by",
      },
      {
        header: "Inspected On",
        dataKey: "inspected_on",
        cellRenderer: (cell) => fd(cell),
      },
      {
        header: "QA Complete",
        dataKey: "qa_completed",
        cellRenderer: (cell) => (cell ? "Yes" : "No"),
      },
    ];
  }, []);

  const OpenInType = showInNewWindow ? NewWindow : SlidingPane;

  return (
    <OpenInType isOpen={isOpen} title={`Bolt List - ${project?.display_name}`} onRequestClose={toggle} width="70%">
      <TableTorch
        selectable
        onSelect={(rowsSelected) => {
          const guids = _.map(rowsSelected, "guid");
          selectModelGUIDS(guids, tc_model_id);
        }}
        data={project_qa_bolted_connections}
        columns={columns}
      />
    </OpenInType>
  );
};

export default connect(mapStateToProps, { getProjectBoltedConnections })(BoltListPane);
