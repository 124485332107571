import React, { Fragment, useEffect, useState } from "react";
import SlidingPane from "react-sliding-pane";
import { connect, useSelector } from "react-redux";
import { getProjectBoltedConnectionByGuids, saveMultipleProjectBoltedConnections } from "modules/project/qa/bolted_connections/actions";
import { Badge, Button, Card, Col, Collapse, FormGroup, Label, Row } from "reactstrap";
import { Field, Form } from "react-final-form";
import { CreatableMultiselect, InputField, ReactSelect, required, SingleDateField } from "modules/4_final_form/web";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import _ from "lodash";
import { getUserManagements } from "modules/user_managements/actions";
import { OnChange } from "react-final-form-listeners";
import { CardHeader } from "framework7-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { Chip } from "@mui/material";
import { PacmanLoader } from "react-spinners";
import NewWindow from "react-new-window";
import moment from "moment";

const BoltQaPane = (props) => {
  const { isOpen, toggle, project_id, tc_model_id, selectedObjects, showInNewWindow } = props;
  const OpenInType = showInNewWindow ? NewWindow : SlidingPane;

  const [saving, setSaving] = useState(false);
  const [selectedBoltedConnections, setSelectedBoltedConnections] = useState([]);

  const project_qa_bolted_connections = useSelector((state) => state.project_qa_bolted_connections);
  const users = useSelector((state) => state.users);

  const filtered_bolts = _.filter(selectedObjects, { class: "IFCMECHANICALFASTENER" });

  useEffect(() => {
    const guids = _.map(filtered_bolts, "guid");
    if (_.isNotEmpty(guids)) {
      props.getProjectBoltedConnectionByGuids(project_id, guids);
    }
    props.getUserManagements();
  }, []);

  useEffect(() => {
    setSelectedBoltedConnections([]);
    _.each(filtered_bolts, (selectedObj) => {
      const guid = selectedObj?.guid;
      let findBoltedConnection = _.find(project_qa_bolted_connections, { guid: guid.toLowerCase() });
      if (findBoltedConnection) {
        // If we find it we set the selectedboltedconnections array
        if (!findBoltedConnection.inspected_on) {
          findBoltedConnection = { ...findBoltedConnection, inspected_on: moment().format("YYYY-MM-DD") };
        }

        setSelectedBoltedConnections((prev) => [...prev, findBoltedConnection]);
      } else {
        // Else we set up a new array of Newboltedconnections
        const bolt_properties = selectedObj?.properties;

        const fastener = _.find(bolt_properties, { name: "IfcMechanicalFastener" });
        const diameter = _.find(fastener?.properties, { name: "NominalDiameter" })?.value;
        const length = _.find(fastener?.properties, { name: "NominalLength" })?.value;
        const size = `M${diameter}*${length}`;

        const ifcMaterial = _.find(bolt_properties, { name: "IfcMaterial" });
        const material = _.find(ifcMaterial?.properties, { name: "Material" })?.value;
        const grade = material?.replace(/[a-zA-Z]/g, "");
        const joint_type = material?.match(/[a-zA-Z]/g).join("");

        const boltObject = {
          guid: guid,
          size: size,
          grade: grade,
          joint_type: joint_type,
          inspected_on: moment().format("YYYY-MM-DD"),
        };
        setSelectedBoltedConnections((prev) => [...prev, boltObject]);
      }
    });
  }, [project_qa_bolted_connections]);

  return (
    <OpenInType isOpen={isOpen} onRequestClose={toggle} onUnload={toggle} width="95%" title="Bolt QA">
      {saving && <PacmanLoader />}

      {!saving && (
        <Form
          component={BoltQAForm}
          selectedBoltedConnections={_.uniqBy(selectedBoltedConnections, "guid")}
          onSubmit={(values) => {
            setSaving(true);
            const req = props.saveMultipleProjectBoltedConnections(project_id, values.bolted_connections_attributes);
            req.then(() => {
              toggle();
              toastr.success("Bolt QA Saved");
              setSaving(false);
            });
            req.catch(() => {
              setSaving(false);
              toastr.error("Error saving bolt QA");
            });
          }}
          selectedBoltedConnections={selectedBoltedConnections}
          // initialValues={{ bolted_connections_attributes: selectedBoltedConnections }}
          mutators={{
            ...arrayMutators,
          }}
          toggle={toggle}
          users={users}
        />
      )}
    </OpenInType>
  );
};

export default connect(null, { getProjectBoltedConnectionByGuids, getUserManagements, saveMultipleProjectBoltedConnections })(BoltQaPane);

const BoltQAForm = (props) => {
  const { selectedBoltedConnections, handleSubmit, users, values, toggle, form, errors } = props;
  const bolted_connections_attributes = _.get(values, "bolted_connections_attributes");

  useEffect(() => {
    form.change("bolted_connections_attributes", selectedBoltedConnections);
  }, [selectedBoltedConnections]);

  return (
    <Fragment>
      <FieldArray name="bolted_connections_attributes" users={users} errors={errors} component={BoltedConnectionsAttributes} />
      {_.isNotEmpty(selectedBoltedConnections) && <Fragment></Fragment>}
      <Row>
        <Col>
          {_.isNotEmpty(bolted_connections_attributes) && (
            <Button color="success" onClick={handleSubmit}>
              Save Bolt QA
            </Button>
          )}
        </Col>
        <Col>
          <Button color="danger" className="text-right" onClick={toggle}>
            Close
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
};

const BoltedConnectionsAttributes = (props) => {
  const {
    fields,
    fields: { value },
    users,
    errors,
  } = props;

  if (fields.length == 0) {
    return (
      <Fragment>
        <div>At least one bolted connection needs to be selected.</div>
        <hr />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Row>
        <Col>
          <b>Selected Bolted Connections</b>
        </Col>
      </Row>

      {fields.map((field, index) => {
        const bolted_connection = value[index];

        return (
          <BoltedConnectionFields
            bolted_connection={bolted_connection}
            users={users}
            field={field}
            key={index}
            hasErrors={errors?.bolted_connections_attributes?.[index] ? true : false}
            removeBoltedConnection={() => {
              fields.remove(index);
            }}
          />
        );
      })}

      <hr />
      <h5>Bolt Tensioning Details</h5>
      <FormGroup row>
        <Label md={2}>Batch Number</Label>
        <Col md={4}>
          <Field name="batch_number" component={InputField} validate={required} />
          <OnChange name="batch_number">
            {(value, previous) => {
              fields.forEach((field, index) => {
                fields.update(index, { ...fields.value[index], batch_number: value });
              });
            }}
          </OnChange>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={2}>Tensioned By</Label>
        <Col md={4}>
          <Field
            name="tensioned_by_id"
            component={ReactSelect}
            validate={required}
            options={_.map(users, (u) => {
              return { value: u.id, label: u.name };
            })}
          />
          <OnChange name="tensioned_by_id">
            {(value, previous) => {
              fields.forEach((field, index) => {
                fields.update(index, { ...fields.value[index], tensioned_by_id: value });
              });
            }}
          </OnChange>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={2}>Inspected By</Label>
        <Col md={4}>
          <Field
            name="inspected_by_id"
            component={ReactSelect}
            validate={required}
            options={_.map(users, (u) => {
              return { value: u.id, label: u.name };
            })}
          />
          <OnChange name="inspected_by_id">
            {(value, previous) => {
              fields.forEach((field, index) => {
                fields.update(index, { ...fields.value[index], inspected_by_id: value });
              });
            }}
          </OnChange>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label md={2}>Inspected On</Label>
        <Col md={4}>
          <Field
            name="inspected_on"
            component={SingleDateField}
            validate={required}
            enablePreviousDates
            initialValue={moment().format("YYYY-MM-DD")}
          />
          <OnChange name="inspected_on">
            {(value, previous) => {
              fields.forEach((field, index) => {
                fields.update(index, { ...fields.value[index], inspected_on: value });
              });
            }}
          </OnChange>
        </Col>
      </FormGroup>
      <hr />
    </Fragment>
  );
};

const BoltedConnectionFields = (props) => {
  const { bolted_connection, field, users, removeBoltedConnection, hasErrors } = props;
  const toggle = () => setItemOpen(!itemOpen);
  const [itemOpen, setItemOpen] = useState(false);

  let warning = null;
  if (bolted_connection.qa_completed) {
    warning = "This bolted connection already has QA. Saving this form will overwrite the previous QA!";
  }

  const label = `${bolted_connection?.qty} qty - ${bolted_connection?.size} - ${bolted_connection?.main_assembly} - ${bolted_connection?.grade} ${bolted_connection?.joint_type} - Phase ${bolted_connection?.phase} - Lot ${bolted_connection.lot}`;

  return (
    <Card>
      <CardHeader style={{ cursor: "pointer" }}>
        <Row style={{ width: "100%" }}>
          <Col onClick={toggle} md={11}>
            <FontAwesomeIcon className="mr-4" icon={itemOpen ? faAngleUp : faAngleDown} size="lg" />
            {label}
            {warning && (
              <Chip
                label={warning}
                className="ml-2"
                style={{
                  color: "#545CD8",
                  borderColor: "#545CD8",
                  border: "2px solid",
                  fontWeight: "bold",
                }}
              />
            )}
            {hasErrors && (
              <Badge className="ml-5" color="danger">
                Error!
              </Badge>
            )}
          </Col>
          <Col md={1}>
            <Button
              className="ml-5"
              color="danger"
              onClick={() => {
                removeBoltedConnection();
              }}
            >
              Remove
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <Collapse isOpen={itemOpen} className="p-2">
        {(itemOpen || !bolted_connection.id) && (
          <Fragment>
            <FormGroup row>
              <Label md={2}>Main Assembly</Label>
              <Col md={2}>
                <Field component={InputField} size="sm" name={`${field}.main_assembly`} disabled={bolted_connection?.id} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={2}>Connected Assemblies</Label>
              <Col>
                <Field
                  component={CreatableMultiselect}
                  size="sm"
                  name={`${field}.connected_assemblies`}
                  format={(values) => {
                    return _.map(values, (value) => {
                      if (_.isString(value)) {
                        return { value: value, label: value };
                      } else {
                        return value;
                      }
                    });
                  }}
                  parse={(values) => {
                    return _.map(values, (value) => {
                      return value.value;
                    });
                  }}
                  multi
                  disabled={bolted_connection?.id}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={2}>Phase</Label>
              <Col>
                <Field component={InputField} size="sm" name={`${field}.phase`} validate={required} disabled={bolted_connection?.id} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={2}>Lot</Label>
              <Col>
                <Field component={InputField} size="sm" name={`${field}.lot`} validate={required} disabled={bolted_connection?.id} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={2}>Size</Label>
              <Col>
                <Field component={InputField} size="sm" name={`${field}.size`} validate={required} disabled={bolted_connection?.id} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={2}>Qty</Label>
              <Col>
                <Field component={InputField} size="sm" name={`${field}.qty`} validate={required} disabled={bolted_connection?.id} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={2}>Grade</Label>
              <Col>
                <Field component={InputField} size="sm" name={`${field}.grade`} validate={required} disabled={bolted_connection?.id} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={2}>Joint Type</Label>
              <Col>
                <Field component={InputField} size="sm" name={`${field}.joint_type`} validate={required} disabled={bolted_connection?.id} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={2}>Batch Number</Label>
              <Col>
                <Field component={InputField} size="sm" name={`${field}.batch_number`} validate={required} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={2}>Tensioned By</Label>
              <Col>
                <Field
                  name={`${field}.tensioned_by_id`}
                  component={ReactSelect}
                  validate={required}
                  options={_.map(users, (u) => {
                    return { value: u.id, label: u.name };
                  })}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={2}>Inspected By</Label>
              <Col>
                <Field
                  name={`${field}.inspected_by_id`}
                  component={ReactSelect}
                  validate={required}
                  options={_.map(users, (u) => {
                    return { value: u.id, label: u.name };
                  })}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={2}>Inspected On</Label>
              <Col>
                <Field
                  name={`${field}.inspected_on`}
                  component={SingleDateField}
                  validate={required}
                  enablePreviousDates
                  initialValue={moment().format("YYYY-MM-DD")}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={2}>GUID</Label>
              <Col>
                <Field name={`${field}.guid`} component={InputField} disabled enablePreviousDates />
              </Col>
            </FormGroup>
          </Fragment>
        )}
      </Collapse>
    </Card>
  );
};
