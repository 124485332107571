import React, { useEffect } from "react";

import { Card, CardHeader, CardBody, Button, Popover, Tooltip, UncontrolledTooltip, Row, Col } from "reactstrap";
import TrimbleConnectSlidingPane from "./index/TrimbleConnectSlidingPane";
import _ from "lodash";
import { useSelector } from "react-redux";
import Toggle from "modules/5_view_helpers/web/Toggle";

import { compressGUID, decompressGUID } from "modules/5_view_helpers/guidConverter";
import CurrentQASlidingPane from "./index/CurrentQASlidingPane";
import AreasSlidingPane from "./index/AreasSlidingPane";

import * as TrimbleConnectWorkspace from "trimble-connect-workspace-api";
import BoltQaPane from "./index/BoltQaPane";
import BoltListPane from "./index/BoltListPane";
import { selectModelGUIDS } from "./helpers";

const QAIndex = (props) => {
  const current_user = useSelector((state) => state.current_user);
  const projects = useSelector((state) => state.projects);
  const current_project = projects[current_user?.current_project_id];

  const tc_project_id = current_project?.tc_project_id;
  const tc_model_id = current_project?.tc_model_id;

  const [allProps, setAllProps] = React.useState([]);

  const [showInNewWindow, setShowInNewWindow] = React.useState(false);
  const [InfoOpen, setInfoOpen] = React.useState(false);
  const [QAOpen, setQAOpen] = React.useState(false);
  const [AreasOpen, setAreasOpen] = React.useState(false);
  const [BoltQAOpen, setBoltQAOpen] = React.useState(false);
  const [BoltListOpen, setBoltListOpen] = React.useState(false);

  const [selectedObjects, setSelectedObjects] = React.useState([]);

  async function onTrimbleLoaded() {
    const API = await TrimbleConnectWorkspace.connect(document.getElementById("viewer"), (event, args) => {
      if (event == "viewer.onSelectionChanged") {
        selectionChanged(args);
      }
      if (event == "viewer.onModelStateChanged") {
        if (args?.data?.state === "loaded") {
          // getObjects()
        }
      }
    });
    window.tc_api = API;
  }

  async function getObjects() {
    const objects = await tc_api.viewer.getObjects(tc_model_id);
    const objectIds = objects?.[0]?.objects.map((object) => object.id);
    const properties = await tc_api.viewer.getObjectProperties(tc_model_id, objectIds);
    setAllProps(properties);
  }

  const selectionChanged = (args) => {
    const objectRuntimeIds = _.get(args, "data[0].objectRuntimeIds");
    tc_api.viewer.getObjectProperties(tc_model_id, objectRuntimeIds).then((properties) => {
      tc_api.viewer.convertToObjectIds(tc_model_id, objectRuntimeIds).then((guids, index) => {
        const decompressedGuids = _.map(guids, decompressGUID);
        const returnSelectedObjects = _.map(properties, (p, i) => {
          return { ...p, guid: decompressedGuids[i] };
        });
        setSelectedObjects(returnSelectedObjects);
      });

      // setSelectedObjects(properties);
    });

    // tc_api.viewer.convertToObjectIds(tc_model_id, objectRuntimeIds).then((guids) => {
    //   const decompressedGuids = _.map(guids, decompressGUID);
    //   setSelectedGuids(decompressedGuids);
    // });
  };

  let trimbleURL = `https://web.connect.trimble.com/projects/${tc_project_id}/viewer/3d/`;
  if (tc_model_id) {
    trimbleURL += `?modelId=${tc_model_id}`;
  }

  const onlyBoltsSelected = _.every(selectedObjects, (o) => o.class == "IFCMECHANICALFASTENER");

  return (
    <Card>
      <CardHeader>
        Quality Assurance
        <Button
          onClick={() => {
            setBoltQAOpen(!BoltQAOpen);
          }}
          style={{ marginLeft: "250px" }}
          color="success"
          id="BoltQA"
          disabled={_.isEmpty(selectedObjects)}
        >
          Do Bolt QA
        </Button>
        <Button
          onClick={() => {
            setBoltListOpen(!BoltListOpen);
          }}
          className="ml-2"
          color="secondary"
          id="BoltList"
        >
          Show Bolt List
        </Button>
        {/* <Button
          color="primary"
          className="m-2"
          onClick={() => {
            setQAOpen(!QAOpen);
          }}
        >
          Show Current QA
        </Button> */}
        <Toggle
        containerStyle={{paddingLeft:"200px"}}
        containerClassName="ml-2 mt-3 text-right"
          label="Popout"
          checked={showInNewWindow}
          color="success"
          onClick={() => {
            setShowInNewWindow(!showInNewWindow);
          }}
        />
      </CardHeader>
      <CardBody>
        {/* <Col md={2}></Col> */}
        {/* <Row>
          <Col md={2}>
            <Toggle
              label="Show in window"
              checked={showInNewWindow}
              color="success"
              onClick={() => {
                setShowInNewWindow(!showInNewWindow);
              }}
            />
          </Col>
          <Col>
            {/* <Button
              color="primary"
              className="m-2"
              onClick={() => {
                setAreasOpen(!AreasOpen);
              }}
            >
              Areas
            </Button>

            <Button
              onClick={() => {
                setInfoOpen(true);
              }}
              className="m-2"
              color="primary"
              disabled={_.isEmpty(selectedObjects)}
              id="QAButton"
            >
              QA of Selected
            </Button>
            {_.isEmpty(selectedObjects) && (
              <UncontrolledTooltip placement="bottom" target="QAButton">
                Select an object in the model to view QA
              </UncontrolledTooltip>
            )} */}
        {/* </Col> */}
        {/* </Row>  */}

        {tc_project_id && (
          <iframe
            // src="https://web.connect.trimble.com/?isEmbedded=true"
            src={trimbleURL}
            id="viewer"
            sandbox="allow-scripts allow-modals allow-forms allow-same-origin allow-popups"
            width="100%"
            onLoad={() => {
              onTrimbleLoaded();
            }}
            height="650px"
          />
        )}

        {!tc_project_id && (
          <div className="text-center">
            <h2>Please set the trimble connect project and model to view the model</h2>
          </div>
        )}
      </CardBody>
      <TrimbleConnectSlidingPane
        isOpen={InfoOpen}
        selectedObjects={selectedObjects}
        modelID={tc_model_id}
        toggle={() => {
          setInfoOpen(!InfoOpen);
        }}
      />

      {QAOpen && (
        <CurrentQASlidingPane
          onSelected={(selectedAssemblies) => {
            const uncompressedGuids = _.map(selectedAssemblies, "guid");
            selectModelGUIDS(uncompressedGuids, tc_model_id);
          }}
          isOpen={QAOpen && tc_api}
          project_id={current_project?.id}
          toggle={() => {
            setQAOpen(!QAOpen);
          }}
          showInNewWindow={showInNewWindow}
          {...props}
        />
      )}

      {AreasOpen && (
        <AreasSlidingPane
          isOpen={AreasOpen}
          project_id={current_project?.id}
          toggle={() => {
            setAreasOpen(!AreasOpen);
          }}
          showInNewWindow={showInNewWindow}
          tc_model_id={tc_model_id}
          {...props}
        />
      )}

      {BoltQAOpen && (
        <BoltQaPane
          isOpen={BoltQAOpen}
          project_id={current_project?.id}
          toggle={() => {
            setBoltQAOpen(!BoltQAOpen);
          }}
          showInNewWindow={showInNewWindow}
          tc_model_id={tc_model_id}
          selectedObjects={selectedObjects}
          {...props}
        />
      )}
      {BoltListOpen && (
        <BoltListPane
          isOpen={BoltListOpen}
          project={current_project}
          toggle={() => {
            setBoltListOpen(!BoltListOpen);
          }}
          showInNewWindow={showInNewWindow}
          tc_model_id={tc_model_id}
          {...props}
        />
      )}
    </Card>
  );
};

export default QAIndex;
