import { combineReducers } from "redux";
// import { reducer as formReducer } from 'redux-form'

import { CurrentUserReducer } from "modules/current_user/reducer";
import { PendingReducer } from "./pending"; // For Spinners
import { ErrorsReducer } from "./errors"; // For Errors

// Project Related
import { ProjectsReducer } from "modules/projects/reducer";
import { ProjectAreaReducer } from "modules/project/areas/reducer";
import { ProjectVariationNumbersReducer } from "modules/project/variation_numbers/reducer";

// QA
import { ProjectQaActionItemsReducer } from "modules/project/qa/action_items/reducer";
import { ProjectQaActionItemsStatusesReducer } from "modules/project/qa/action_items/statuses/reducer";
import { ProjectQaActionItemsCategoriesReducer } from "modules/project/qa/action_items/categories/reducer";
import { ProjectQaPaintInspectionsReducer } from "modules/project/qa/paint_inspections/reducer";

// Site QA
import { ProjectQaSiteSiteInstallationReducer } from "modules/project/qa/site/site_installations/reducer";
import { ProjectQaSiteSurveillancesReducer } from "modules/project/qa/site/surveillances/reducer";

// Project Related
import { EmployeeTasksReducer } from "modules/employee/tasks/reducer";
import { EmployeeQualificationsReducer } from "modules/employee/qualifications/reducer";
import { EmployeesReducer } from "modules/employees/reducer";
import { EmployeeTrainingRequirementsReducer } from "modules/employee/training_requirements/reducer";

import { DepartmentsReducer } from "modules/departments/reducer";
import { InternalCompaniesReducer } from "modules/internal_companies/reducer";
import { PositionsReducer } from "modules/positions/reducer";

import { UserManagementsReducer } from "modules/user_managements/reducer";
import { UserGroupsReducer } from "modules/user_groups/reducer";

import { RolesReducer } from "modules/roles/reducer";

import ThemeOptions from "./web/ThemeOptions";
import FilterValuesReducer from "modules/5_view_helpers/web/ArrayFilter/actions.js";

// Training Related
import { TrainingCourseReducer } from "modules/training/courses/reducer";
import { TrainingCompanyReducer } from "modules/training/companies/reducer";
import { TrainingCourseCategoryReducer } from "modules/training/course_categories/reducer";
import { TrainingBookingReducer } from "modules/training/bookings/reducer";
import { TrainingNzqaNumbersReducer } from "modules/training/nzqa_numbers/reducer";

// QA Settings Related
import { ProjectsQaSiteChecklistItemReducer } from "modules/projects/qa/site/checklist_items/reducer";

// Production Related
import { ProductionJobBoardReducer } from "modules/production/job_boards/reducer";
import { ProductionPpBoardReducer } from "modules/production/pp_boards/reducer";

import { ProductionStrumisContractsReducer } from "modules/production/strumis/contracts/reducer";
import { ProductionStrumisWorkstationsReducer } from "modules/production/strumis/workstations/reducer";
import { ProductionStrumisContractMarkInstancesReducer } from "modules/production/strumis/contract/mark_instances/reducer";
import { ProductionStrumisContractMarkItemInstancesReducer } from "modules/production/strumis/contract/mark_item_instances/reducer";
import { ProductionStrumisProductionDeliveryNotesReducer } from "modules/production/strumis/production/delivery_notes/reducer";

import { ProductionStrumisContractBundlesReducer } from "modules/production/strumis/production/contract/bundles/reducer";
import { ProductionStrumisSectionSizesReducer } from "modules/production/strumis/section_sizes/reducer";
import { ProductionStrumisSectionTypesReducer } from "modules/production/strumis/section_types/reducer";

import { ProductionStrumisContractDocumentsReducer } from "modules/production/strumis/contract/documents/reducer";

import { ProductionScheduleReducer } from "modules/production/schedule/reducer";

// Hireages

import { HireagesExternalReducer } from "modules/hireages/externals/reducer";
import { HireagesCategoryReducer } from "modules/hireages/categories/reducer";

// Moneyworks
import { MWJobsReducer } from "modules/moneyworks/jobs/reducer";
import { MWNamesReducer } from "modules/moneyworks/names/reducer";
import { MWJobsheetsReducer } from "modules/moneyworks/jobsheet_items/reducer";

// Paint
import { PaintProductsReducer } from "modules/paint/products/reducer";
import { PaintCodeDetailsReducer } from "modules/paint/code_details/reducer";
import { PaintProductCategoriesReducer } from "modules/paint/product_categories/reducer";
import { PaintTreatmentGradesReducer } from "modules/paint/treatment_grades/reducer";
import { PaintLocationsReducer } from "modules/paint/locations/reducer";
import { PaintSurfacePrepsReducer } from "modules/paint/surface_preps/reducer";

import { ProjectTechnicalDetailsPaintSystemsReducer } from "modules/project/technical_details/paint_systems/reducer";

// Assemblies
import { ProjectAssembliesReducer } from "modules/project/assemblies/reducer";

// MRP
import { ProjectDrawingsReducer } from "modules/project/drawings/reducer";
import { ProjectSiteDocumentReleasesReducer } from "modules/project/site_document_releases/reducer";
import { DrawingOfficeDrawingTypesReducer } from "modules/drawing_office/drawing_types/reducer";

import { TempDrawingsReducer } from "modules/project/temp_drawings/reducer.js";

import { CustomFormsReducer } from "modules/custom_forms/reducer";
import { CustomFormRecordsReducer } from "modules/custom_form/records/reducer";
import { CustomFormsMenusReducer } from "modules/custom_forms/menus/reducer"


// Welding Inspections
import { ProjectQaWeldingInspectionsReducer } from "modules/project/qa/welding_inspections/reducer";
import { ProjectQaWeldingInspectionsAccessRestrictionsReducer } from "modules/project/qa/welding_inspections/access_restrictions/reducer";
import { ProjectQaWeldingInspectionsSurfaceConditionsReducer } from "modules/project/qa/welding_inspections/surface_conditions/reducer";
import { ProjectQaWeldingInspectionsViewingConditionsReducer } from "modules/project/qa/welding_inspections/viewing_conditions/reducer";
import { ProjectQaWeldingInspectionsWeldingStandardsReducer } from "modules/project/qa/welding_inspections/welding_standards/reducer";
import { ProjectQaWeldingInspectionsInspectionSpecsReducer } from "modules/project/qa/welding_inspections/inspection_specs/reducer";
import { ProjectQaWeldingInspectionsWeldingStagesReducer } from "modules/project/qa/welding_inspections/welding_stages/reducer";
import { ProjectQaWeldingInspectionsWeldingProcessesReducer } from "modules/project/qa/welding_inspections/welding_processes/reducer";
import { ProjectQaWeldingInspectionsWeldingTypesReducer } from "modules/project/qa/welding_inspections/welding_types/reducer";
import { ProjectQaWeldingInspectionsWeldingProceduresReducer } from "modules/project/qa/welding_inspections/welding_procedures/reducer";
import { ProjectQaWeldingInspectionsFailureReasonsReducer } from "modules/project/qa/welding_inspections/failure_reasons/reducer";
import { ProjectQaWeldingInspectionsFilletSizesReducer } from "modules/project/qa/welding_inspections/fillet_sizes/reducer";
import { ProjectQaWeldingInspectionsInspectionCriteriasReducer } from "modules/project/qa/welding_inspections/inspection_criterias/reducer";

// Communication Chains

import { ProjectCommunicationsReducer } from "modules/project/communications/reducer";
import { ProjectCommunicationsStatusesReducer } from "modules/project/communications/statuses/reducer";
import { ProjectCommunicationsOutgoingTypesReducer } from "modules/project/communications/outgoing_types/reducer";

import { ProjectCommunicationsCustomReferencesReducer } from "modules/project/communications/custom_references/reducer";

// Address Book
import { AddressBookCompaniesReducer } from "modules/address_book/companies/reducer";
import { AddressBookCompanyContactsReducer } from "modules/address_book/company/contacts/reducer";

// V2 Maintenance Categories
import { V2MaintenanceCategoriesReducer } from "modules/v2/maintenance/categories/reducer";
import { V2MaintenanceItemsReducer } from "modules/v2/maintenance/items/reducer";

// Dropzone Reducers NO API Request
import { ReactDropzoneStatus } from "modules/4_final_form/ReactDropzone/reducers";
import { StoreBookoutItemsReducer } from "modules/store/bookout_items/reducer";
import { MWProductsReducer } from "modules/moneyworks/products/reducer";

import { ProjectBoltedConnectionsReducer } from "modules/project/qa/bolted_connections/reducer"


const rootReducer = combineReducers({
  // training related
  training_courses: TrainingCourseReducer,
  training_companies: TrainingCompanyReducer,
  training_course_categories: TrainingCourseCategoryReducer,
  training_bookings: TrainingBookingReducer,
  training_nzqa_numbers: TrainingNzqaNumbersReducer,

  pending: PendingReducer,
  errors: ErrorsReducer,

  employees: EmployeesReducer,
  employee_qualifications: EmployeeQualificationsReducer,
  employee_tasks: EmployeeTasksReducer,
  employee_training_requirements: EmployeeTrainingRequirementsReducer,

  // Settings
  internal_companies: InternalCompaniesReducer,
  positions: PositionsReducer,
  departments: DepartmentsReducer,

  // For all projects
  projects: ProjectsReducer,
  project_assemblies: ProjectAssembliesReducer,
  projects_qa_site_checklist_items: ProjectsQaSiteChecklistItemReducer,

  project_areas: ProjectAreaReducer,
  project_variation_numbers: ProjectVariationNumbersReducer,

  project_qa_site_site_installations: ProjectQaSiteSiteInstallationReducer,
  project_qa_site_surveillances: ProjectQaSiteSurveillancesReducer,
  project_qa_action_items: ProjectQaActionItemsReducer,
  project_qa_action_items_statuses: ProjectQaActionItemsStatusesReducer,
  project_qa_action_items_categories: ProjectQaActionItemsCategoriesReducer,

  // Tech Details
  project_technical_details_paint_systems: ProjectTechnicalDetailsPaintSystemsReducer,

  project_qa_paint_inspections: ProjectQaPaintInspectionsReducer,

  // Production Reducers
  production_job_boards: ProductionJobBoardReducer,
  production_pp_boards: ProductionPpBoardReducer,

  production_strumis_contracts: ProductionStrumisContractsReducer,
  production_strumis_workstations: ProductionStrumisWorkstationsReducer,
  production_strumis_contract_mark_instances: ProductionStrumisContractMarkInstancesReducer,
  production_strumis_contract_mark_item_instances: ProductionStrumisContractMarkItemInstancesReducer,
  production_strumis_production_delivery_notes: ProductionStrumisProductionDeliveryNotesReducer,
  production_strumis_contract_bundles: ProductionStrumisContractBundlesReducer,
  production_strumis_section_sizes: ProductionStrumisSectionSizesReducer,
  production_strumis_section_types: ProductionStrumisSectionTypesReducer,
  production_strumis_contract_documents: ProductionStrumisContractDocumentsReducer,

  production_schedule: ProductionScheduleReducer,

  // Hireage
  hireages_externals: HireagesExternalReducer,
  hireages_categories: HireagesCategoryReducer,

  // Moneyworks
  mw_jobs: MWJobsReducer,
  mw_names: MWNamesReducer,
  mw_products: MWProductsReducer,
  store_bookout_items: StoreBookoutItemsReducer,

  // Paint
  paint_products: PaintProductsReducer,
  paint_product_categories: PaintProductCategoriesReducer,
  paint_treatment_grades: PaintTreatmentGradesReducer,
  paint_locations: PaintLocationsReducer,
  paint_surface_preps: PaintSurfacePrepsReducer,
  paint_code_details: PaintCodeDetailsReducer,

  // MRP
  project_site_document_releases: ProjectSiteDocumentReleasesReducer,
  project_drawings: ProjectDrawingsReducer,
  drawing_office_drawing_types: DrawingOfficeDrawingTypesReducer,

  temp_drawings: TempDrawingsReducer,
  custom_forms: CustomFormsReducer,
  custom_form_records: CustomFormRecordsReducer,
  custom_forms_menus: CustomFormsMenusReducer,

  // Welding
  project_qa_welding_inspections: ProjectQaWeldingInspectionsReducer,
  project_qa_welding_inspections_access_restrictions: ProjectQaWeldingInspectionsAccessRestrictionsReducer,
  project_qa_welding_inspections_viewing_conditions: ProjectQaWeldingInspectionsViewingConditionsReducer,
  project_qa_welding_inspections_surface_conditions: ProjectQaWeldingInspectionsSurfaceConditionsReducer,
  project_qa_welding_inspections_inspection_specs: ProjectQaWeldingInspectionsInspectionSpecsReducer,
  project_qa_welding_inspections_welding_standards: ProjectQaWeldingInspectionsWeldingStandardsReducer,
  project_qa_welding_inspections_welding_stages: ProjectQaWeldingInspectionsWeldingStagesReducer,
  project_qa_welding_inspections_welding_processes: ProjectQaWeldingInspectionsWeldingProcessesReducer,
  project_qa_welding_inspections_welding_types: ProjectQaWeldingInspectionsWeldingTypesReducer,
  project_qa_welding_inspections_welding_procedures: ProjectQaWeldingInspectionsWeldingProceduresReducer,
  project_qa_welding_inspections_failure_reasons: ProjectQaWeldingInspectionsFailureReasonsReducer,
  project_qa_welding_inspections_fillet_sizes: ProjectQaWeldingInspectionsFilletSizesReducer,
  project_qa_welding_inspections_inspection_criterias: ProjectQaWeldingInspectionsInspectionCriteriasReducer,

  // Communication Chains

  project_communications: ProjectCommunicationsReducer,
  project_communications_statuses: ProjectCommunicationsStatusesReducer,
  project_communications_outgoing_types: ProjectCommunicationsOutgoingTypesReducer,
  project_communications_custom_references: ProjectCommunicationsCustomReferencesReducer,
  project_qa_bolted_connections: ProjectBoltedConnectionsReducer,

  // Address Book
  address_book_companies: AddressBookCompaniesReducer,
  address_book_company_contacts: AddressBookCompanyContactsReducer,

  // V2
  v2_maintenance_categories: V2MaintenanceCategoriesReducer,
  v2_maintenance_items: V2MaintenanceItemsReducer,
  react_dropzone_status: ReactDropzoneStatus,

  user_groups: UserGroupsReducer,
  current_user: CurrentUserReducer,
  roles: RolesReducer,
  users: UserManagementsReducer,
  filterValues: FilterValuesReducer,
  ThemeOptions,
});

export default rootReducer;
