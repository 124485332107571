import _ from "lodash";
import { compressGUID } from "modules/5_view_helpers/guidConverter";


export function selectModelGUIDS(uncompressedGuids, tc_model_id) {
    if (_.isNotEmpty(uncompressedGuids) && tc_api && tc_model_id) {
        const compressedGuids = _.map(uncompressedGuids, compressGUID);
        tc_api.viewer.convertToObjectRuntimeIds(tc_model_id, compressedGuids).then((objectRuntimeIds) => {
            const modelObjectIds = [
                {
                    modelId: tc_model_id,
                    objectRuntimeIds: objectRuntimeIds,
                },
            ];
            tc_api.viewer.setSelection({ modelObjectIds: modelObjectIds });
        });
    }
}